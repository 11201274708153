<template>
  <a-upload
    :showUploadList="false"
    action="https://web.frameflash.com/admin-api/common/local/file/uploadCos"
    @change="handleChangeEn"
  >
    <a-button type="primary" v-if="customType == 1 ? customInfo.subtitleCn : customInfo.subtitleEn">更换</a-button>
    <a-button v-else> <a-icon type="upload" /> 上传 </a-button>
  </a-upload>
</template>

<script>
import {updateGather} from "@/api/video/gather";

export default {
  name: "gatherUploadBtn",
  props:{
    customInfo:{
      type:Object,
    },
    customIndex:{
      type:Number
    },
    customType:{
      type:Number
    }
  },

  methods:{
    handleChangeEn(info) {
      console.log(info)
      var fileType = info.file.name;
      if (fileType.indexOf('vtt') < 0) {
        this.$message.warning('请上传vtt文件');
      }
      if(this.customType == 1) {
        //中文
        this.customInfo.subtitleCn =  info.file.response.data.mediaUrl
        // this.$set(this.list[this.signleIndex],'subtitleCn',info.file.response.data.mediaUrl);
        updateGather(this.customInfo).then(res => {
          this.$emit("change")
        })

      }

      if(this.customType == 2) {
        //英文
        this.customInfo.subtitleEn =  info.file.response.data.mediaUrl;

        updateGather(this.customInfo).then(res => {
          this.$emit("change")
        })
      }

    },
  }
}
</script>

<style scoped>

</style>