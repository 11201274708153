import request from '@/utils/request'


// 查询剧集列表
export function listGather(query) {
  return request({
    url: '/video/video-gathers/list',
    method: 'get',
    params: query
  })
}

// 导入用户
export function importData (data) {
  return request({
    url: '/video/video-gathers/importData',
    method: 'post',
    data: data
  })
}

// 查询剧集分页
export function pageGather(query) {
  return request({
    url: '/video/video-gathers/page',
    method: 'get',
    params: query
  })
}

// 查询剧集详细
export function getGather(data) {
  return request({
    url: '/video/video-gathers/detail',
    method: 'get',
    params: data
  })
}

// 新增剧集
export function addGather(data) {
  return request({
    url: '/video/video-gathers/add',
    method: 'post',
    data: data
  })
}

// 修改剧集
export function updateGather(data) {
  return request({
    url: '/video/video-gathers/edit',
    method: 'post',
    data: data
  })
}

// 删除剧集
export function delGather(data) {
  return request({
    url: '/video/video-gathers/delete',
    method: 'post',
    data: data
  })
}
